import React from 'react';
import { Subscribe } from 'unstated';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import {
	ContentStateStateContainer,
	type EditorContentStateQueryType,
} from '@confluence/content-state';
import { fg } from '@confluence/feature-gating';

import { useElementVisibility, ELEMENTS } from '../hooks/useIsElementVisible';

import { StatusRow } from './StatusRow';
import { ClassificationRow } from './ClassificationRow';
import { LabelsSection } from './LabelsSection';
import { RedactionsRow } from './RedactionsRow';

const i18n = defineMessages({
	propertiesSectionTitle: {
		id: 'details-panel.info-section.title',
		defaultMessage: 'Info',
		description:
			'Title for the section containing info related to content (e.g. status, classification, labels)',
	},
});

const propertiesSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

type ContentState = {
	state: EditorContentStateQueryType['singleContent'];
};

export const InfoSection = () => {
	const { isElementVisible } = useElementVisibility();

	return (
		<Stack xcss={propertiesSectionStyles}>
			<FormattedMessage {...i18n.propertiesSectionTitle} tagName="h4" />
			<Stack>
				{isElementVisible(ELEMENTS.ROWS.STATUS) && (
					<Subscribe to={[ContentStateStateContainer]}>
						{({ state }: ContentState) => {
							const { name = null, restrictionLevel, color = null } = state?.contentState ?? {};
							return (
								<StatusRow
									name={name}
									restrictionLevel={restrictionLevel}
									color={color}
									isContentStateAvailable={!!state?.contentState}
								/>
							);
						}}
					</Subscribe>
				)}
				<ClassificationRow />
				{isElementVisible(ELEMENTS.ROWS.REDACTIONS) && fg('dlp_cc-redactions-metadata-modal') && (
					<RedactionsRow />
				)}
				{isElementVisible(ELEMENTS.ROWS.LABELS) && <LabelsSection />}
			</Stack>
		</Stack>
	);
};
