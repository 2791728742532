import React, { useEffect, memo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { Flex, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlaskit/theme/constants';
import { SpotlightTarget, SpotlightTransition, SpotlightManager } from '@atlaskit/onboarding';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';
import { useReactProps_DO_NOT_USE } from '@atlassian/embedded-confluence/useReactProps';
import type { IframePassThroughProps } from '@atlassian/embedded-confluence/IframeElementType';

import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useRouteName } from '@confluence/route-manager';
import { PDF_EXPORT_PAGE } from '@confluence/named-routes';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import {
	AIFloatingContextMenu,
	AISmartButtonStateHandler,
	AISmartButtonWrapper,
} from '@confluence/ai-floating-context-menu';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { fg } from '@confluence/feature-gating';
import { PageHeaderLoomEntryPoint } from '@confluence/page-header-loom-button';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';
import { useObjectSidebar } from '@confluence/object-sidebar-api';
import { useSpaceId } from '@confluence/space-utils';
import { useIsLoomDisabled } from '@confluence/loom-utils';
import { LoadableLazy } from '@confluence/loadable';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { usePreviewPanelWidth } from '@confluence/preview-panel-api';

import { OBJECT_SIDEBAR_TOUR_TARGETS } from './objectSidebarTourTargets';
import { DetailsPanelButton } from './sidebar-buttons/DetailsPanelButton';
import { WorkflowsPanelButton } from './sidebar-buttons/WorkflowsPanelButton';
import { ObjectSidebarReactionsPicker } from './sidebar-buttons/ObjectSidebarReactionsPicker';
import { CommentPanelButton } from './sidebar-buttons/CommentPanelButton';
import { FeedbackCollectorButton } from './sidebar-buttons/FeedbackCollectorButton';

const ObjectSidebarSpotlightTour = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ObjectSidebarSpotlightTour" */ './ObjectSidebarSpotlightTour'
			)
		).ObjectSidebarSpotlightTour,
});

const objectSidebarStyles = css({
	position: 'fixed',
	right: 0,
	bottom: 0,
	paddingRight: `${token('space.300')}`,
	paddingBottom: `${token('space.100')}`,
	zIndex: layers.dialog(),
	pointerEvents: 'none',
	// The selector below ensures the elements behind the empty space taken up by the container remain clickable/responsive to mouse events
	// eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors
	'& > *': {
		pointerEvents: 'auto',
	},
});

const viewModeStyles = css({
	//z index is set to 1 to ensure that the object sidebar is below the inline comment component
	zIndex: 1,
});

const quickstartSpacing = css({
	marginBottom: `${token('space.1000')}`,
	right: `${token('space.300')}`,
});

const quickstartLivePageSpacing = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '110px',
	right: `${token('space.300')}`,
});

const objectSidebarContainerStyles = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '64px',
	width: '48px',
	marginLeft: 'auto',
});

const squircleObjectSidebarContainerStyles = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '12px',
	width: '48px',
	marginLeft: 'auto',
});

const collapsibleObjectSidebarContainerStyles = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '64px',
});

const collapsibleSquircleObjectSidebarContainerStyles = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '12px',
});

const collapsibleContainerStyles = css({
	width: `${token('space.600')}`,
	marginLeft: 'auto',
});

const collapsibleBottomSidebarStyles = xcss({
	paddingTop: 'space.100',
	marginBottom: 'space.050',
});

const collapsibleToggleStyles = css({
	margin: 'auto',
	width: `${token('space.400')}`,
});

const dividerStyle = xcss({
	borderBottom: `2px solid ${token('color.border')}`,
	display: 'inline-block',
	width: '16px',
	height: '1px',
});

const bottomSidebarStyles = css({
	marginTop: token('space.100'),
	marginBottom: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '1px',
});

const hiddenStyles = css({
	display: 'none',
});

const i18n = defineMessages({
	expandToggleLabel: {
		id: 'object-sidebar-components.expand-toggle-label',
		defaultMessage: 'Expand action bar',
		description: 'Label for button to expand the floating action bar',
	},
	collapseToggleLabel: {
		id: 'object-sidebar-components.collapse-toggle-label',
		defaultMessage: 'Collapse action bar',
		description: 'Label for button to collapse the floating action bar',
	},
});

type ObjectSidebarControlProps = {
	hasCommentsButton?: boolean;
	hasDetailsButton?: boolean;
	hasLoomButton?: boolean;
	hasAiButton?: boolean;
	hasReactionsButton?: boolean;
	contentId: string;
	spaceKey?: string;
	contentType?: string | null;
	lastModifiedDate?: string | null;
	pageCommentsSectionInView?: boolean;
	reactionsPickerPreventOverflowOptions?: Record<string, any>;
};

export const ObjectSidebarControl = memo(
	({
		hasCommentsButton,
		hasDetailsButton,
		hasLoomButton,
		hasAiButton,
		hasReactionsButton,
		contentId,
		spaceKey,
		contentType,
		lastModifiedDate,
		pageCommentsSectionInView,
		reactionsPickerPreventOverflowOptions,
	}: ObjectSidebarControlProps) => {
		const { isFullScreenEmbedView } = useReactProps_DO_NOT_USE<IframePassThroughProps>() || {};

		const { formatMessage } = useIntl();
		const [actionBarIsHovered, setActionBarIsHovered] = useState(false);
		const [
			{ sidebarControlState, sidebarControlExpandCollapsedState },
			{ setSidebarControlState, toggleObjectSidebarControl, setSidebarControlExpandCollapsedState },
		] = useObjectSidebar();

		const pageMode = useGetPageMode();
		const spaceId = useSpaceId();
		const isLoomDisabled = useIsLoomDisabled();

		const rightSidebarContext = useRightSidebarContext();
		const { width: globalSidebarWidth } = rightSidebarContext.getCurrent() || {};
		const { width: previewPanelWidth } = usePreviewPanelWidth();

		const isOnPDFExportPageRouteArgs = {
			selector: (routeName: string | undefined) => {
				if (!routeName) {
					return false;
				}
				return routeName === PDF_EXPORT_PAGE.name;
			},
		};

		const isOnPDFExportPageRoute = useRouteName(isOnPDFExportPageRouteArgs);

		const isLivePage = useIsCurrentPageLive();
		const renderServerPlaceholder = useRenderServerPlaceholder();

		const { isDismissed } = useQuickstartState();
		const { isQuickstartEligible } = useQuickstartEligible();
		const isQuickStartPillShowing = !isDismissed && isQuickstartEligible();

		// whenever the contentID changes we needed to reset the action bar to be expanded
		useEffect(() => {
			setSidebarControlExpandCollapsedState('expanded');
		}, [contentId, setSidebarControlExpandCollapsedState]);

		useEffect(() => {
			// This check can be removed when we are rendering the object sidebar
			// control on server as well so server and client have same output and
			// hydrateRoot doesn't break
			if (!renderServerPlaceholder) {
				setSidebarControlState('shown');
				setSidebarControlExpandCollapsedState('expanded');
			}

			return () => {
				setSidebarControlState('hidden');
				setSidebarControlExpandCollapsedState('collapsed');
			};
		}, [setSidebarControlState, renderServerPlaceholder, setSidebarControlExpandCollapsedState]);

		const sidebarIsExpanded = sidebarControlExpandCollapsedState === 'expanded';

		// Collapsed actions are rendered when collapsed. These can be used e.g. to enable keyboard shortcuts while collapsed.
		const collapsedActions = [];

		const primaryActions: JSX.Element[] = [];
		if (hasCommentsButton && fg('confluence-frontend-comments-panel')) {
			primaryActions.push(<CommentPanelButton contentId={contentId} />);
			if (!sidebarIsExpanded) {
				collapsedActions.push(<CommentPanelButton contentId={contentId} collapsed />);
			}
		}
		if (hasDetailsButton && fg('confluence_frontend_details_panel')) {
			primaryActions.push(<DetailsPanelButton contentId={contentId} />);
		}
		if (fg('document_workflows')) {
			primaryActions.push(<WorkflowsPanelButton />);
		}

		const secondaryActions: JSX.Element[] = [];

		if (hasReactionsButton) {
			secondaryActions.push(
				<ObjectSidebarReactionsPicker
					contentId={contentId}
					spaceId={spaceId ?? ''}
					contentSubType={pageMode}
					contentType={contentType ?? ''}
					reactionsPickerPreventOverflowOptions={reactionsPickerPreventOverflowOptions}
				/>,
			);
		}

		if (hasLoomButton && !isLoomDisabled && fg('confluence_frontend_object_sidebar_loom')) {
			secondaryActions.push(
				<PageHeaderLoomEntryPoint
					contentId={contentId}
					crossSellPopupPlacement="left-end"
					spaceKey={spaceKey ?? ''}
					contentType={contentType ?? ''}
					isCircle={!fg('ai-smart-button-team-25')}
					testId="sidebar-loom-button"
					source="contentWrapper"
				/>,
			);
		}

		// Add feedback collector
		secondaryActions.push(<FeedbackCollectorButton contentId={contentId} />);

		if (isOnPDFExportPageRoute) {
			return null;
		}

		const globalSidebarMargin =
			pageMode === PageMode.VIEW && globalSidebarWidth ? globalSidebarWidth : 0;
		const previewPanelMargin =
			pageMode === PageMode.VIEW && previewPanelWidth ? previewPanelWidth : 0;
		const shouldRenderWhenEmbedded = isEmbeddedConfluence_DO_NOT_USE()
			? isFullScreenEmbedView && fg('confluence_support_content_wrapper_in_ep_m1')
			: !isEmbeddedConfluence_DO_NOT_USE();

		if (renderServerPlaceholder && !fg('confluence_frontend_object_sidebar_ssr')) {
			return null;
		}

		if (fg('confluence_frontend_collapsible_action_bar')) {
			// If there's no AI button, when we collapse the action bar, we need to render an expand icon within the
			// existing action bar container. If there is an AI button, hovering over it should render the expand icon
			const shouldRenderIntegratedExpandButton = !hasAiButton && !sidebarIsExpanded;
			// We always render the toggle component into the DOM, but we change its visibility based on:
			// If the action bar is expanded: show on hover (regardless of AI button)
			// If the action bar is collapsed: always show if AI button present, otherwise never show
			const shouldDisplayHoverToggle =
				(sidebarIsExpanded && actionBarIsHovered) || (hasAiButton && !sidebarIsExpanded);

			const handleMouseEnter = () => {
				setActionBarIsHovered(true);
			};

			const handleMouseLeave = () => {
				setActionBarIsHovered(false);
			};

			const onToggleClick = () => {
				toggleObjectSidebarControl();
				// need to make sure the hover disappears after clicking it
				setActionBarIsHovered(false);
			};

			const collapseExpandToggle = (
				<div
					css={collapsibleToggleStyles}
					style={{
						paddingBottom: sidebarIsExpanded ? token('space.100') : undefined,
						visibility: shouldDisplayHoverToggle ? 'visible' : 'hidden',
					}}
					data-testid="collapsible-hover-toggle"
				>
					<IconButton
						appearance="subtle"
						icon={sidebarIsExpanded ? ChevronDownIcon : ChevronUpIcon}
						label={
							sidebarIsExpanded
								? formatMessage(i18n.collapseToggleLabel)
								: formatMessage(i18n.expandToggleLabel)
						}
						onClick={() => onToggleClick()}
						shape="circle"
						testId="collapsible-hover-button"
					/>
				</div>
			);

			const integratedExpandButton = (
				<div>
					<IconButton
						appearance="subtle"
						icon={ChevronUpIcon}
						label={formatMessage(i18n.expandToggleLabel)}
						onClick={() => toggleObjectSidebarControl()}
						shape="circle"
						testId="integrated-expand-button"
					/>
				</div>
			);

			return (
				<>
					<AISmartButtonStateHandler
						spaceKey={spaceKey ?? ''}
						contentId={contentId}
						contentType={contentType ?? ''}
						lastModifiedDate={lastModifiedDate}
					/>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
					<div
						css={[
							objectSidebarStyles,
							pageMode === PageMode.VIEW && viewModeStyles,
							isQuickStartPillShowing && !isLivePage && quickstartSpacing,
							isQuickStartPillShowing && isLivePage && quickstartLivePageSpacing,
						]}
						data-testid="object-sidebar-container"
						data-vc="object-sidebar-container"
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
							marginRight: globalSidebarMargin + previewPanelMargin,
						}}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{primaryActions.length + secondaryActions.length > 0 &&
							!isEmbeddedConfluence_DO_NOT_USE() &&
							fg('confluence_frontend_object_sidebar') && (
								<SpotlightManager>
									<SpotlightTarget name={OBJECT_SIDEBAR_TOUR_TARGETS.OBJECT_SIDEBAR_CONTROL}>
										<div css={collapsibleContainerStyles}>
											{collapseExpandToggle}
											{sidebarIsExpanded ? (
												<Flex
													xcss={
														fg('ai-smart-button-team-25')
															? collapsibleSquircleObjectSidebarContainerStyles
															: collapsibleObjectSidebarContainerStyles
													}
													direction="column"
													gap="space.100"
													justifyContent="center"
													alignItems="center"
													testId="object-sidebar-control"
												>
													{primaryActions}
													{secondaryActions.length > 0 && <Box xcss={dividerStyle} />}
													{secondaryActions}
												</Flex>
											) : (
												<>{collapsedActions}</>
											)}
											{shouldRenderIntegratedExpandButton ? (
												<Flex
													xcss={
														fg('ai-smart-button-team-25')
															? collapsibleSquircleObjectSidebarContainerStyles
															: collapsibleObjectSidebarContainerStyles
													}
													direction="column"
													gap="space.100"
													justifyContent="center"
													alignItems="center"
													testId="object-sidebar-control"
												>
													{integratedExpandButton}
												</Flex>
											) : null}
										</div>
									</SpotlightTarget>
									<SpotlightTransition>
										<ObjectSidebarSpotlightTour contentId={contentId} />
									</SpotlightTransition>
								</SpotlightManager>
							)}
						{hasAiButton && (
							<Box xcss={collapsibleBottomSidebarStyles}>
								{fg('ai-smart-button-team-25') ? (
									<div
										style={{
											// needed because the ::after background-image expands out beyond the container
											// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
											paddingLeft: '1px',
										}}
									>
										<AISmartButtonWrapper
											contentId={contentId}
											contentType={contentType ?? ''}
											pageMode={pageMode}
										/>
									</div>
								) : (
									<AIFloatingContextMenu
										spaceKey={spaceKey ?? ''}
										contentId={contentId}
										contentType={contentType ?? ''}
										lastModifiedDate={lastModifiedDate}
										pageCommentsSectionInView={pageCommentsSectionInView}
										isLivePage={isLivePage}
										isInObjectSideBar
									/>
								)}
							</Box>
						)}
					</div>
				</>
			);
		}

		return (
			<>
				<AISmartButtonStateHandler
					spaceKey={spaceKey ?? ''}
					contentId={contentId}
					contentType={contentType ?? ''}
					lastModifiedDate={lastModifiedDate}
				/>
				<div
					css={[
						objectSidebarStyles,
						pageMode === PageMode.VIEW && viewModeStyles,
						isQuickStartPillShowing && !isLivePage && quickstartSpacing,
						isQuickStartPillShowing && isLivePage && quickstartLivePageSpacing,
						sidebarControlState === 'hidden' && hiddenStyles,
					]}
					data-testid="object-sidebar-container"
					data-vc="object-sidebar-container"
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						marginRight: globalSidebarMargin + previewPanelMargin,
					}}
				>
					{primaryActions.length + secondaryActions.length > 0 &&
						shouldRenderWhenEmbedded &&
						fg('confluence_frontend_object_sidebar') && (
							<SpotlightManager>
								<SpotlightTarget name={OBJECT_SIDEBAR_TOUR_TARGETS.OBJECT_SIDEBAR_CONTROL}>
									<Flex
										xcss={
											fg('ai-smart-button-team-25')
												? squircleObjectSidebarContainerStyles
												: objectSidebarContainerStyles
										}
										direction="column"
										gap="space.100"
										justifyContent="center"
										alignItems="center"
										testId="object-sidebar-control"
									>
										{primaryActions}
										{secondaryActions.length > 0 && <Box xcss={dividerStyle} />}
										{secondaryActions}
									</Flex>
								</SpotlightTarget>
								<SpotlightTransition>
									<ObjectSidebarSpotlightTour contentId={contentId} />
								</SpotlightTransition>
							</SpotlightManager>
						)}
					{hasAiButton && (
						<div css={bottomSidebarStyles}>
							{fg('ai-smart-button-team-25') ? (
								<AISmartButtonWrapper
									contentId={contentId}
									contentType={contentType ?? ''}
									pageMode={pageMode}
								/>
							) : (
								<AIFloatingContextMenu
									spaceKey={spaceKey ?? ''}
									contentId={contentId}
									contentType={contentType ?? ''}
									lastModifiedDate={lastModifiedDate}
									pageCommentsSectionInView={pageCommentsSectionInView}
									isLivePage={isLivePage}
									isInObjectSideBar
								/>
							)}
						</div>
					)}
				</div>
			</>
		);
	},
);
