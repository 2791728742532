import { useCallback, useEffect } from 'react';
import type { ApolloError } from 'apollo-client';

import { fg } from '@confluence/feature-gating';
import {
	useGetOnboardingState,
	deserializeState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';
import { useSessionData } from '@confluence/session-data';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { useRouteName } from '@confluence/route-manager';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';

import { LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS } from '../constants/onboardingStateKeys';

import { useIsLivePagesAvailableInSpace } from './useIsLivePagesAvailableInSpace';
import { useIsNav4Changeboarding } from './useIsNav4Changeboarding';
import { useIsEstablishedUser } from './useIsEstablishedUser';

type LiveDocChangeboarding = Record<
	'introSpotlight' | 'liveDocTour' | 'editorControlsTour' | 'contentWrapperTour' | 'aiButtonTour',
	{
		isVisible: boolean;
		complete: () => void;
	}
> & {
	error?: ApolloError;
	loading?: true;
};

export const noChangeboarding: LiveDocChangeboarding = {
	introSpotlight: {
		isVisible: false,
		complete: () => {},
	},
	liveDocTour: {
		isVisible: false,
		complete: () => {},
	},
	editorControlsTour: {
		isVisible: false,
		complete: () => {},
	},
	contentWrapperTour: {
		isVisible: false,
		complete: () => {},
	},
	aiButtonTour: {
		isVisible: false,
		complete: () => {},
	},
};

const IS_ON_SPACE_OVERVIEW_ROUTES_ARGS = {
	selector: (routeName: string | undefined) => routeName === SPACE_OVERVIEW.name,
};

export const isLiveDocChangeboardingActive = (changeboarding: LiveDocChangeboarding): boolean =>
	Object.values(changeboarding).some((tour) => {
		if (typeof tour === 'object' && tour !== null && 'isVisible' in tour) {
			return tour.isVisible;
		}
		return false;
	});

export const useLiveDocChangeboarding: () => LiveDocChangeboarding = () => {
	const { setOnboardingState } = useSetOnboardingState();
	const { isAdminHubAIEnabled } = useSessionData();
	const {
		isEstablishedUser,
		loading: isEstablishedUserLoading,
		error: isEstablishedUserError,
	} = useIsEstablishedUser({
		skip:
			!fg('cc_onboarding_changeboarding_for_live_pages_eap') &&
			!fg('confluence_frontend_object_sidebar_changeboarding') &&
			!fg('confluence_ai_uber_button_changeboarding') &&
			!fg('cc_onboarding_editor_controls_changeboarding'),
	});
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const { data, loading, error } = useGetOnboardingState(
		[...Object.values(LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS)],
		!fg('cc_onboarding_changeboarding_for_live_pages_eap') &&
			!fg('confluence_frontend_object_sidebar_changeboarding') &&
			!fg('confluence_ai_uber_button_changeboarding') &&
			!fg('cc_onboarding_editor_controls_changeboarding'),
	);

	const {
		hasDismissedIntroSpotlight,
		hasDismissedLiveDocTour,
		hasDismissedEditorControlsTour,
		hasDismissedContentWrapperTour,
		hasDismissedAiButtonTour,
	} = deserializeState(data);

	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();
	const isLivePagesAvailableInSpace = useIsLivePagesAvailableInSpace();
	const isLivePage = useIsCurrentPageLive();
	const isEditPage = useIsEditorPage();
	const { sidebarControlState } = useObjectSidebarState();
	const isContentWrapperVisible = sidebarControlState === 'shown';
	const isOnSpaceOverviewRoute = useRouteName(IS_ON_SPACE_OVERVIEW_ROUTES_ARGS);
	const isAiButtonVisible =
		isContentWrapperVisible &&
		isAdminHubAIEnabled &&
		(isLivePage
			? fg('confluence_ai_uber_button_in_live_pages')
			: fg('confluence_ai_uber_button_in_object_sidebar'));

	const showIntroSpotlight =
		isTwoPageTypesExperience &&
		!hasDismissedIntroSpotlight &&
		isLivePagesAvailableInSpace &&
		!isLivePage &&
		fg('cc_onboarding_changeboarding_for_live_pages_eap');

	const showLiveDocTour =
		isLivePage &&
		isTwoPageTypesExperience &&
		!hasDismissedLiveDocTour &&
		fg('cc_onboarding_changeboarding_for_live_pages_eap');

	/**
	 * This gate is more of an internally safety check
	 * This flag is not firing an exposure event as well so order doesn't matter
	 */
	const showEditorControlsTour =
		// eslint-disable-next-line confluence-feature-gating/no-preconditioning
		(isEditPage || (isLivePage && isLiveEditMode)) &&
		!hasDismissedEditorControlsTour &&
		fg('cc_onboarding_editor_controls_changeboarding') &&
		expValEqualsNoExposure('platform_editor_controls', 'cohort', 'variant1');

	const showContentWrapperTour =
		!isOnSpaceOverviewRoute &&
		isContentWrapperVisible &&
		!hasDismissedContentWrapperTour &&
		fg('confluence_frontend_object_sidebar_changeboarding');

	const showAiButtonTour =
		!isOnSpaceOverviewRoute &&
		isAiButtonVisible &&
		!hasDismissedAiButtonTour &&
		fg('confluence_ai_uber_button_changeboarding');

	const {
		isUserEnrolledInNav4Changeboarding,
		isUserCompletedNav4Changeboarding,
		loading: nav4Loading,
		error: nav4Error,
	} = useIsNav4Changeboarding();

	const completeIntroSpotlight = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedIntroSpotlight,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeLiveDocTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedLiveDocTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeEditorControlsTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedEditorControlsTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeContentWrapperTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedContentWrapperTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeAiButtonTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedAiButtonTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	// Skip onboarding for new users if features are enabled, by marking eligible onboarding as complete.
	// If we did not check to see if they were eligible for each onboarding before marking it complete,
	// we would risk marking onboarding complete when the feature is not yet enabled.
	useEffect(() => {
		if (error || loading || isEstablishedUserError || isEstablishedUserLoading) {
			return;
		}
		if (!isEstablishedUser) {
			if (showIntroSpotlight) completeIntroSpotlight();
			if (showLiveDocTour) completeLiveDocTour();
			if (showEditorControlsTour) completeEditorControlsTour();
			if (showContentWrapperTour) completeContentWrapperTour();
			if (showAiButtonTour) completeAiButtonTour();
		}
	}, [
		isEstablishedUser,
		showIntroSpotlight,
		showLiveDocTour,
		showEditorControlsTour,
		showContentWrapperTour,
		showAiButtonTour,
		completeAiButtonTour,
		completeContentWrapperTour,
		completeIntroSpotlight,
		completeLiveDocTour,
		completeEditorControlsTour,
		error,
		isEstablishedUserError,
		isEstablishedUserLoading,
		loading,
	]);

	if (error || nav4Error) {
		return {
			...noChangeboarding,
			error: error || nav4Error,
		};
	}

	if (loading || nav4Loading || isEstablishedUserLoading) {
		return { ...noChangeboarding, loading: true };
	}

	// Do not start Live Doc changeboarding until eligible user has completed nav4 changeboarding
	if (isUserEnrolledInNav4Changeboarding && !isUserCompletedNav4Changeboarding) {
		return noChangeboarding;
	}

	if (!isEstablishedUser) {
		return noChangeboarding;
	}

	if (showLiveDocTour) {
		return {
			...noChangeboarding,
			liveDocTour: {
				isVisible: true,
				complete: completeLiveDocTour,
			},
		};
	}

	if (showEditorControlsTour) {
		return {
			...noChangeboarding,
			editorControlsTour: {
				isVisible: true,
				complete: completeEditorControlsTour,
			},
		};
	}

	if (showContentWrapperTour) {
		return {
			...noChangeboarding,
			contentWrapperTour: {
				isVisible: true,
				complete: completeContentWrapperTour,
			},
		};
	}

	if (showAiButtonTour) {
		return {
			...noChangeboarding,
			aiButtonTour: {
				isVisible: true,
				complete: completeAiButtonTour,
			},
		};
	}

	if (showIntroSpotlight) {
		return {
			...noChangeboarding,
			introSpotlight: {
				isVisible: true,
				complete: completeIntroSpotlight,
			},
		};
	}

	return noChangeboarding;
};
